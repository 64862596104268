<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>告警列表</span>
      </div>
      <div class="search">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="告警类型">
            <el-select size="small" v-model="form.alarmType" clearable placeholder="请选择告警类型">
              <el-option
                v-for="item in alarmType"
                :label="item.name"
                :value="item.code"
                :key="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="处理状态">
            <el-select size="small" v-model="form.handleStatus" clearable placeholder="请选择处理状态">
              <el-option
                v-for="item in handleStatus"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="taskCode" label="告警编号">
            <template slot-scope="scope">
              {{ scope.row.document.taskCode }}
            </template>
          </el-table-column>
          <el-table-column prop="title" label="规则名称">
            <template slot-scope="scope">
              {{ scope.row.document.title }}
            </template>
          </el-table-column>
          <el-table-column prop="alarmTypeCode" label="告警类型">
            <template slot-scope="scope">
              <span v-show="scope.row.document.alarmTypeCode=='operationTaskRuleExpiration'">巡点检规则过期告警</span>
              <!-- <span v-show="scope.row.document.alarmTypeCode=='operationTaskDailyReport'">巡点检日报</span> -->
              <span v-show="scope.row.document.alarmTypeCode=='lubeTaskRuleExpiration'">润滑规则过期告警</span>
              <span v-show="scope.row.document.alarmTypeCode=='configuration'">组态告警</span>
            </template>
          </el-table-column>
          <el-table-column prop="content" label="告警内容" width="200">
            <template slot-scope="scope">
              {{ scope.row.document.content }}
            </template>
          </el-table-column>
          <el-table-column prop="patrolName" label="告警级别">
            <template slot-scope="scope">
              <el-tag size="small" v-show="scope.row.document.level==1">提示告警</el-tag>
              <el-tag type="info" size="small" v-show="scope.row.document.level==2">普通告警</el-tag>
              <el-tag type="warning" size="small" v-show="scope.row.document.level==3">重要告警</el-tag>
              <el-tag type="danger" size="small" v-show="scope.row.document.level==4">严重告警</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="endDate" label="告警时间">
            <template slot-scope="scope">
              {{ scope.row.document.endDate ? dayjs(scope.row.document.endDate).format('YYYY-MM-DD HH:mm:ss'):'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="patrolName" label="处理状态">
            <template slot-scope="scope">
              <el-tag type="warning" size="small" v-show="scope.row.document.handleStatus==0">未处理</el-tag>
              <el-tag type="success" size="small" v-show="scope.row.document.handleStatus==1">已处理</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="handleTime" label="处理时间">
            <template slot-scope="scope">
              {{ scope.row.document.handleTime ? dayjs(scope.row.document.handleTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.document.createTime ? dayjs(scope.row.document.createTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :disabled="scope.row.document.handleStatus==1"><div @click="handle(scope.row)">处理</div></el-dropdown-item>
                  <!-- <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      alarmType: [
        {code: 'operationTaskRuleExpiration',name: '巡点检规则过期告警'},
        // {code: 'operationTaskDailyReport',name: '巡点检日报'},
        {code: 'lubeTaskRuleExpiration',name: '润滑规则过期告警'},
        {code: 'configuration',name: '组态告警'},
      ],
      handleStatus: [
        {id: 0,name: '未处理'},
        {id: 1,name: '已处理'}
      ],
      form: {},
      tableData: []
    }
  },
  methods: {
    dayjs,
    handle(row) {
      this.$confirm("确认处理告警事件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true
        this.$ajax.post("alarmMessageHandle", {
          alarmId: row.id
        }).then((res) => {
          this.loadListData();
          this.$message.success('处理成功')
          this.loading = false
        }).catch(err=>{
          this.loading = false
        })
      })
    },
    details(row) {
      this.$router.push({ name: 'lubricationRecordDetail', params: {row: row}})
    },
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadListData()
    },
    loadListData() {
      this.loading = true
      this.$ajax.post('alarmMessageQuery', {
        alarmTypeCode: this.form.alarmType?this.form.alarmType:null,
        handleStatus: this.form.handleStatus!=null?this.form.handleStatus:null,
        pageNum: this.page,
        pageSize: this.size
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
  },
  mounted() {
    this.loadListData()
    this.getLastPage()
    this.getSize()
  },
  destroyed() {

  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
</style>